<template>
	<div class="content">
		<!-- Main Information -->
		<div class="lineform">
			<span class="title">Main Information</span>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col left">Vessel</div>
					<div class="tableHeader content-col must">Name</div>
				</el-col>
				<el-col :span="5">
					<el-input
						v-model="form.vesselName"
						:placeholder="form.vesselName?.toString() !== '' ? '' : errorInputTip"
						:class="form.vesselName?.toString() !== '' ? '' : 'errorInput'"
					></el-input>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col must">IMO Num</div>
				</el-col>
				<el-col :span="5">
					<el-input
						v-model="form.imoNum"
						:placeholder="form.imoNum?.toString() !== '' ? '' : errorInputTip"
						:class="form.imoNum?.toString() !== '' ? '' : 'errorInput'"
					></el-input>
				</el-col>
				<el-col :span="3"><div class="tableHeader content-col must">Call Sign</div></el-col>
				<el-col :span="5">
					<el-input
						v-model="form.callSign"
						:placeholder="form.callSign?.toString() !== '' ? '' : errorInputTip"
						:class="form.callSign?.toString() !== '' ? '' : 'errorInput'"
					></el-input>
				</el-col>
			</el-row>
		</div>

		<!-- Position and Time Information -->
		<div class="lineform">
			<span class="title">Position and Time Information</span>
			<el-row class="line-row">
				<el-col :span="3"><div class="tableHeader content-col">Stop Position</div> </el-col>
				<el-col :span="8">
					<div class="formContent">
						<div class="tableHeader content-col left must">Latitude</div>
						<el-input
							class="lonLat"
							v-model="form.stopPositionLatitudeLeft"
							placeholder=""
							:class="form.stopPositionLatitudeLeft?.toString() !== '' ? '' : 'errorInput'"
						></el-input
						>°
						<el-input
							class="lonLat"
							v-model="form.stopPositionLatitudeCenter"
							placeholder=""
							:class="form.stopPositionLatitudeCenter?.toString() !== '' ? '' : 'errorInput'"
						></el-input
						>′
						<el-select
							class="lonLat"
							v-model="form.stopPositionLatitudeRight"
							placeholder=""
							:class="form.stopPositionLatitudeRight?.toString() !== '' ? '' : 'errorInput'"
						>
							<el-option v-for="data in NS" :label="data" :value="data" :key="data" />
						</el-select>
					</div>
				</el-col>
				<el-col :span="3"><div class="tableHeader content-col must">Longitude</div> </el-col>
				<el-col :span="8">
					<div class="formContent">
						<el-input
							class="lonLat"
							v-model="form.stopPositionLongitudeLeft"
							placeholder=""
							:class="form.stopPositionLongitudeLeft?.toString() !== '' ? '' : 'errorInput'"
						></el-input
						>°
						<el-input
							class="lonLat"
							v-model="form.stopPositionLongitudeCenter"
							placeholder=""
							:class="form.stopPositionLongitudeCenter?.toString() !== '' ? '' : 'errorInput'"
						></el-input
						>′
						<el-select
							class="lonLat"
							v-model="form.stopPositionLongitudeRight"
							placeholder=""
							:class="form.stopPositionLongitudeRight?.toString() !== '' ? '' : 'errorInput'"
						>
							<el-option v-for="data in EW" :label="data" :value="data" :key="data" />
						</el-select>
					</div>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col must">Stop Date</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<mydatePicker
							type="datetime"
							format="yyyy-MM-dd HH:mm"
							value-format="yyyy-MM-dd HH:mm"
							v-model="form.stopDate"
							@change="setData"
							:placeholder="form.stopDate?.toString() !== '' ? '' : errorInputTip"
							:class="form.stopDate?.toString() !== '' ? '' : 'errorInput'"
						></mydatePicker>
						<div class="unit">UTC</div>
					</div>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col must">Dist SLR at Stop</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input
							v-model="form.distSlrAtStop"
							:placeholder="form.distSlrAtStop?.toString() !== '' ? '' : errorInputTip"
							:class="form.distSlrAtStop?.toString() !== '' ? '' : 'errorInput'"
						></el-input>
						<div class="unit">NM</div>
					</div>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="3"><div class="tableHeader content-col">Resume Position</div> </el-col>
				<el-col :span="8">
					<div class="formContent">
						<div class="tableHeader content-col left must">Latitude</div>
						<el-input
							class="lonLat"
							v-model="form.resumePositionLatitudeLeft"
							placeholder=""
							:class="form.resumePositionLatitudeLeft?.toString() !== '' ? '' : 'errorInput'"
						></el-input
						>°
						<el-input
							class="lonLat"
							v-model="form.resumePositionLatitudeCenter"
							placeholder=""
							:class="form.resumePositionLatitudeCenter?.toString() !== '' ? '' : 'errorInput'"
						></el-input
						>′
						<el-select
							class="lonLat"
							v-model="form.resumePositionLatitudeRight"
							placeholder=""
							:class="form.resumePositionLatitudeRight?.toString() !== '' ? '' : 'errorInput'"
						>
							<el-option v-for="data in NS" :label="data" :value="data" :key="data" />
						</el-select>
					</div>
				</el-col>
				<el-col :span="3"><div class="tableHeader content-col must">Longitude</div> </el-col>
				<el-col :span="8">
					<div class="formContent">
						<el-input
							class="lonLat"
							v-model="form.resumePositionLongitudeLeft"
							placeholder=""
							:class="form.resumePositionLongitudeLeft?.toString() !== '' ? '' : 'errorInput'"
						></el-input
						>°
						<el-input
							class="lonLat"
							v-model="form.resumePositionLongitudeCenter"
							placeholder=""
							:class="form.resumePositionLongitudeCenter?.toString() !== '' ? '' : 'errorInput'"
						></el-input
						>′
						<el-select
							class="lonLat"
							v-model="form.resumePositionLongitudeRight"
							placeholder=""
							:class="form.resumePositionLongitudeRight?.toString() !== '' ? '' : 'errorInput'"
						>
							<el-option v-for="data in EW" :label="data" :value="data" :key="data" />
						</el-select>
					</div>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col must">Resume Date</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<mydatePicker
							type="datetime"
							format="yyyy-MM-dd HH:mm"
							value-format="yyyy-MM-dd HH:mm"
							v-model="form.resumeData"
							@change="setData"
							:placeholder="form.resumeData?.toString() !== '' ? '' : errorInputTip"
							:class="form.resumeData?.toString() !== '' ? '' : 'errorInput'"
						></mydatePicker>
						<div class="unit">UTC</div>
					</div>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col must">Dist Remain at Resume</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input
							v-model="form.distRemainAtResume"
							:placeholder="form.distRemainAtResume?.toString() !== '' ? '' : errorInputTip"
							:class="form.distRemainAtResume?.toString() !== '' ? '' : 'errorInput'"
						></el-input>
						<div class="unit">NM</div>
					</div>
				</el-col>
			</el-row>
			<el-row class="line-row">
				<el-col :span="3">
					<div class="tableHeader content-col must">Stop Reason</div>
				</el-col>
				<el-col :span="5">
					<el-input
						v-model="form.stopReason"
						:placeholder="form.stopReason?.toString() !== '' ? '' : errorInputTip"
						:class="form.stopReason?.toString() !== '' ? '' : 'errorInput'"
					></el-input>
				</el-col>
				<el-col :span="3">
					<div class="tableHeader content-col must">Stoppage Time</div>
				</el-col>
				<el-col :span="5">
					<div class="formContent">
						<el-input
							v-model="form.stoppageTime"
							:placeholder="form.stoppageTime?.toString() !== '' ? '' : errorInputTip"
							:class="form.stoppageTime?.toString() !== '' ? '' : 'errorInput'"
						></el-input>
						<div class="unit">HRS</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<!-- Fuel -->
		<div class="lineform">
			<span class="title">Fuel</span>
			<el-row class="line-row">
				<el-col :span="4"><div class="tableHeader content-col">(MT)</div></el-col>
				<el-col :span="5"><div class="tableHeader content-col">HFO</div></el-col>
				<el-col :span="5"><div class="tableHeader content-col">LSFO</div></el-col>
				<el-col :span="5"><div class="tableHeader content-col">MDO</div></el-col>
				<el-col :span="5"><div class="tableHeader content-col">MGO</div></el-col>
			</el-row>
			<el-row v-for="(data, index) in fuelData" :key="index" class="line-row">
				<el-col :span="4"
					><div class="content-col" :class="data.must ? 'must' : ''">{{ data.MT }}</div></el-col
				>
				<el-col :span="5" v-for="(value, key) in data" :key="key" v-show="!['MT', 'type', 'must'].includes(key)">
					<el-input
						v-model="form[data[key]]"
						v-if="data['type'] === 'input'"
						@change="changeTable"
						:placeholder="!form[data[key]] && data.must && form[data[key]] !== 0 ? errorInputTip : ''"
						:class="!form[data[key]] && data.must && form[data[key]] !== 0 ? 'errorInput' : ''"
					></el-input>

					<el-select v-model="form[data[key]]" v-else placeholder="">
						<el-option label="区域一" value="shanghai"></el-option>
						<el-option label="区域二" value="beijing"></el-option>
					</el-select>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		textForm: {
			type: Object,
			default: () => {},
		},
	},
	watch: {
		textForm: {
			handler() {
				this.setData('1');
				this.changeTable()
			},
		},
	},
	data() {
		return {
			errorInputTip: 'Field is empty!',
			form: {
				vesselName: '',
				imoNum: '',
				callSign: '',
				stopPositionLatitudeLeft: '',
				stopPositionLatitudeCenter: '',
				stopPositionLatitudeRight: '',
				stopPositionLongitudeLeft: '',
				stopPositionLongitudeCenter: '',
				stopPositionLongitudeRight: '',
				stopDate: '',
				distSlrAtStop: '',
				resumePositionLatitudeLeft: '',
				resumePositionLatitudeCenter: '',
				resumePositionLatitudeRight: '',
				resumePositionLongitudeLeft: '',
				resumePositionLongitudeCenter: '',
				resumePositionLongitudeRight: '',
				resumeData: '',
				distRemainAtResume: '',
				stopReason: '',
				stoppageTime: '',
				stopBrobHfo: 0,
				stopBrobLsfo:0,
				stopBrobMdo: 0,
				stopBrobMgo: 0,
				resumeBrobHfo: 0,
				resumeBrobLsfo:0,
				resumeBrobMdo: 0,
				resumeBrobMgo: 0,
				totalConsSinceStoppageHfo: 0,
				totalConsSinceStoppageLsfo: 0,
				totalConsSinceStoppageMdo: 0,
				totalConsSinceStoppageMgo: 0,
			},

			NS: ['N', 'S'],
			EW: ['E', 'W'],
			EWNS: ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'],
			//
			fuelData: [
				{
					MT: 'Stop BROB',
					HFO: 'stopBrobHfo',
					LSFO: 'stopBrobLsfo',
					MDO: 'stopBrobMdo',
					MGO: 'stopBrobMgo',
					type: 'input',
					must: true,
				},
				{
					MT: 'Resume BROB',
					HFO: 'resumeBrobHfo',
					LSFO: 'resumeBrobLsfo',
					MDO: 'resumeBrobMdo',
					MGO: 'resumeBrobMgo',
					type: 'input',
					must: true,
				},
				{
					MT: 'Total Cons Since Stoppage',
					HFO: 'totalConsSinceStoppageHfo',
					LSFO: 'totalConsSinceStoppageLsfo',
					MDO: 'totalConsSinceStoppageMdo',
					MGO: 'totalConsSinceStoppageMgo',
					type: 'input',
				},
			],
		};
	},
	methods: {
		/**
		 *
		 * @param {*} type  1 左侧文字匹配 || null 输入框计算逻辑
		 */
		setData(type) {
			if (type === '1') {
				this.form = {
					...this.form,
					...this.textForm,
				};
			}
			const date1 = new Date(this.form.stopDate);
			const date2 = new Date(this.form.resumeData);
			const diffInMilliseconds = Math.abs(date2 - date1);
			this.form.stoppageTime = parseFloat((diffInMilliseconds / (1000 * 60 * 60)).toFixed(3));
		},
		changeTable(){
			this.fuelData.forEach(filedList =>{
				for(let key in filedList){
					if (!['MT', 'type', 'must'].includes(key)) {
						if(!this.form[filedList[key]]){
							this.form[filedList[key]] = 0
						}
						console.log(this.form[filedList[key]]);
						if (typeof this.form[filedList[key]] !== 'number') {
							const matches = this.form[filedList[key]].match(/\d+(\.\d+)?/);
							this.form[filedList[key]] = matches ? parseFloat(matches[0]) : 0;
						}
					}
				}
			})
			// 求和
			let typeList = ['Hfo', 'Lsfo', 'Mdo', 'Mgo'];
			let needSumList = ['resumeBrob', 'stopBrob'];
			typeList.forEach((e) => {
				const number = this.form[needSumList[1] + e] * 100000 - this.form[needSumList[0] + e] * 100000
				this.form['totalConsSinceStoppage' + e] = parseFloat((number / 100000).toFixed(3));
			});
		},
		clear() {
			for (let key in this.form) {
				this.form[key] = '';
			}
		},
	},
};
</script>

<style lang="less" scoped>
.content {
	width: 100%;
	height: 100%;
	.lineform {
		width: 100%;
		margin-bottom: 15px;
		.title {
			font-size: 12px;
		}
		.grey {
			background: rgb(245, 245, 245);
			font-size: 0.875rem;
			color: #606266;
		}
		::v-deep .errorInput {
			border-color: #f56c6c !important;
			.el-input__inner {
				border-color: #f56c6c !important;
			}
		}
		// .grey::before{
		// 	content: '*';
		// 	color: #F56C6C;
		// 	margin-right: 0.25rem;
		// }
		::v-deep .form {
			margin-top: 5px;
			border: 1px solid rgba(210, 218, 228, 1);
			display: flex;
			.line {
				width: 1px;
				height: 100%;
				border: 1px solid rgba(210, 218, 228, 1);
			}
			.only {
				.el-form-item__label {
					width: 140px;
				}
			}
			.el-form-item {
				margin: 0;
				display: flex;
				.el-form-item__label {
					display: block;
					background: rgb(245, 245, 245);
					padding-left: 5px;
				}
				.el-form-item__content {
					height: 40px;
				}
			}
		}
		.line-row {
			border: 1px solid rgba(210, 218, 228, 1);
		}
		.content-col {
			height: 40px;
			line-height: 40px;
			white-space: nowrap;
			border: 1px solid rgba(210, 218, 228, 1);
		}
		.must::after {
			content: '*';
			color: #f56c6c;
			margin-right: 0.25rem;
		}
		.left {
			float: left;
			padding: 0 5px;
		}
		.header {
			background: rgb(245, 245, 245);
			text-align: left;
			padding-left: 5px;
		}
		.tableHeader {
			background: rgb(245, 245, 245);
			text-align: center;
		}

		.formContent {
			display: flex;
			align-items: center;
			.lonLat {
				width: 100px;
			}
			.unit {
				margin: 0 5px;
				width: 30px;
				text-align: center;
			}
		}
		.grey-line {
			border: 1px solid rgba(210, 218, 228, 1);
			background: rgb(245, 245, 245);
			height: 22px;
			width: 100%;
		}
	}
}
</style>
